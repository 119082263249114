import { SvgIcon } from "@mui/material";

export default function Logo(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M0 18.2738L2.34072 15.6633C3.51215 16.9686 5.09913 17.4041 6.81627 17.4041C8.94984 17.4041 10.095 16.5048 10.095 14.8514C10.095 14.1256 9.91306 13.5166 9.52294 13.1107C9.15806 12.7343 8.74162 12.5312 7.88297 12.386L5.64573 12.0379C4.05875 11.777 2.88732 11.226 2.05516 10.3267C1.17036 9.3398 0.728499 8.00518 0.728499 6.26552C0.728499 2.58112 3.17395 0 7.18097 0C9.73008 0 11.5783 0.69623 13.1653 2.40761L10.875 4.93071C9.70466 3.6833 8.29958 3.50979 7.07623 3.50979C5.15087 3.50979 4.21416 4.69937 4.21416 6.12029C4.21416 6.64191 4.37063 7.19309 4.76075 7.59904C5.12563 7.97672 5.72397 8.29527 6.47771 8.41223L8.66428 8.76034C10.3547 9.02125 11.4216 9.54287 12.2019 10.3548C13.1916 11.3993 13.6334 12.9074 13.6334 14.7356C13.6334 18.7376 10.6414 21 6.76435 21C3.95419 21 1.82062 20.3331 0 18.2738Z"
        fill="#359740"
      />
      <path
        d="M17.042 20.8264V0.173492H29.244V3.77067H20.6578V8.61527H27.9699V12.2112H20.6578V17.2293H29.244V20.8264H17.042V20.8264Z"
        fill="#359740"
      />
      <path
        d="M32.7842 20.8264V0.173492H44.9862V3.77067H36.4V8.61527H43.7122V12.2112H36.4V17.2293H44.9862V20.8264H32.7842V20.8264Z"
        fill="#359740"
      />
      <path
        d="M75.5077 20.8264L71.8906 12.5888H69.2887V20.8264H65.6729V0.173492H72.932C76.7043 0.173492 78.9416 3.04599 78.9416 6.49684C78.9416 9.39761 77.3546 11.1962 75.5594 11.922L79.6966 20.8264H75.5077ZM72.6973 3.77067H69.2887V9.22428H72.6973C74.2843 9.22428 75.3257 8.09254 75.3257 6.49684C75.3257 4.90241 74.2843 3.77067 72.6973 3.77067Z"
        fill="#359740"
      />
      <path
        d="M93.2269 20.8264L92.1347 17.1714H85.5521L84.4324 20.8264H80.6602L87.3992 0.173492H90.2348L97.0004 20.8264H93.2269ZM88.908 6.26549L86.566 13.7784H91.1715L88.908 6.26549Z"
        fill="#359740"
      />
      <path
        d="M60.1036 2.14669C58.8803 0.783611 57.1368 0.173492 55.2115 0.173492H48.5254V20.8264H52.5793C53.9758 16.8158 56.0895 12.4403 54.6488 5.95777C56.568 12.7179 54.6697 17.0141 53.33 20.8264H55.2115C57.1368 20.8264 58.8803 20.2174 60.1035 18.8543C62.1853 16.533 61.9769 13.5164 61.9769 10.4126C61.9771 7.30984 62.1855 4.4669 60.1036 2.14669ZM54.5227 18.3867C55.5604 15.7915 56.6511 11.6286 55.0992 6.52511C57.1759 9.12624 57.4974 12.97 57.0573 14.7236C60.8157 10.5128 56.9531 5.59313 53.0994 3.49692C55.5567 8.10466 55.1861 12.4462 53.2781 17.6694C47.2836 13.4709 54.4001 8.12211 52.3833 2.36555C61.0887 6.09768 62.6406 14.4906 54.5227 18.3867Z"
        fill="#359740"
      />
    </SvgIcon>
  );
}

const styles = {
    NavMenuContainer: {
        display: "flex",
        flexGrow: 1,
        alignItems: "baseline",
        justifyContent: "flex-start",
        justifyItems: "flex-start",
        width: "100%",
        pl: "calc((100vw - 1300px)/2)",
      },
      MenuLeftBlock: {
        display: "flex",
        justifyContent: "space-between",
        pt: "14px",
      },
      MenuRightBlock: {
        alignItems: "center",
        display: "flex",
      },
};

export default styles;

const classes = {
  iconsStyle: {
    color: "#359740",
    pl: "0",
  },
  Header: {
   
  }
}

export default classes;

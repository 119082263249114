import { SvgIcon } from '@mui/material';

export default function Bundles(props) {
  return <SvgIcon {...props}>
      <path
     d="M 6.022474,4.4289131 C 5.854913,4.9510822 6.2709749,6.106379 6.4542934,6.3077522 L 6.2945747,6.5736998 9.5259527,17.644602 c 0.1816498,0.670349 0.7080873,0.712492 0.9485543,0.649681 l 8.442611,-2.202257 c 0.838397,-0.09905 0.982659,-0.555374 0.949922,-0.771099 L 16.757565,3.8443974 16.412138,3.7102113 C 16.55412,3.4738174 16.636829,2.2582053 16.196839,1.7748953 15.646875,1.1707576 6.2319092,3.7761993 6.022474,4.4289131 Z"
     stroke="currentColor"
     strokeWidth="1.35493"
     id="path10" />
  <path
     d="m 3.3111049,10.34395 c -0.078496,0.55609 0.5964806,1.56224 0.8336558,1.706116 l -0.1202153,0.304395 5.6530557,9.857589 c 0.3271879,0.600827 0.9033239,0.491936 1.1504479,0.362375 l 8.675418,-4.547584 c 0.884996,-0.335264 0.951199,-0.820489 0.873688,-1.021182 L 14.776105,6.7185335 14.377332,6.6862329 C 14.484116,6.4156863 14.335294,5.2087444 13.766088,4.8635127 13.054535,4.4319731 3.4092341,9.6489053 3.3111049,10.34395 Z"
     fill="#ffffff"
     stroke="currentColor"
     strokeWidth="1.35493"
     id="path12" />
  </SvgIcon>;
}
